import { Observable } from "rxjs";
import { RespInterface, RespListInterface } from "@common/ports/interfaces";

import {
  ListPaymentInterface,
  SaleCreateInterface, SaleDeliveryStateInterface,
  SaleInterface, SalePaidApprovedInterface, SalePaidCancelInterface, SalePaidInterface,
  SaleProductCancelInterface, SaleProductNotifyInterface, SaleProductoInterface,
  SaleProductUpdateInterface, SaleUpdateInterface,
  SellingProductInterface
} from "@sales/ports/interfaces";

export abstract class SaleAbstraction {
  abstract list(query: string): Observable<RespListInterface<SaleInterface>>;
  abstract listPayment(query: string): Observable<RespListInterface<ListPaymentInterface>>;
  abstract create(sale: SaleCreateInterface): Observable<RespInterface<string>>;
  abstract update(sale: SaleUpdateInterface): Observable<RespInterface<string>>;
  abstract read(id: string): Observable<RespInterface<SaleInterface>>;
  abstract delete(sale: SaleInterface): Observable<RespInterface<string>>;
  abstract sendEmail(saleId: string): Observable<RespInterface<string>>;

  abstract productCreate(product: SaleProductoInterface): Observable<RespInterface<string>>;
  abstract productUpdate(product: SaleProductUpdateInterface): Observable<RespInterface<string>>;
  abstract productCancel(product: SaleProductCancelInterface): Observable<RespInterface<string>>;
  abstract massShipping(deliveryState: SaleDeliveryStateInterface): Observable<RespInterface<string>>;
  abstract notifyProduct(deliveryState: SaleProductNotifyInterface): Observable<RespInterface<string>>;
  abstract sellingProduct(query: string): Observable<RespListInterface<SellingProductInterface>>;

  abstract paidCreate(paid: SalePaidInterface): Observable<RespInterface<string>>;
  abstract paidApproved(paid: SalePaidApprovedInterface): Observable<RespInterface<string>>;
  abstract paidCancel(paid: SalePaidCancelInterface): Observable<RespInterface<string>>;
  abstract paidUpdate(paid: SalePaidInterface): Observable<RespInterface<string>>;
}
